import React, { useEffect, useRef, useState } from "react";
import ChatView from "./chatView";
import key from '../assets/images/keyboard.svg'
import { useChat } from "../contexts/chatContext";
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


const Chat = () => {

    const [show, setShow] = useState(false);
    const [inputValue, setInputValue] = useState('');
    
    const endOfContainer = useRef();
    const inputRef = useRef();

    const chatData = useChat() || {};
    const {updateChat, simpleLoading} = chatData;

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        inputRef.current.blur();
        await updateChat({
            options:[],
            text: inputValue, 
            agent: 'user'
        })
        setInputValue('');
      }

    
      useEffect(() => {
        if (simpleLoading) {
            setInputValue('');
        }
    }, [simpleLoading])

    return (
        <div>
            <ChatView 
                setShow={setShow} 
                show={show} 
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSubmit={handleSubmit}
                inputRef={inputRef} 
                endOfContainerRef={endOfContainer}
            />
            <div className="w-full px-4 left-0 fixed bottom-10 rounded-full z-30">
                <div className="absolute top-0 left-0 w-full h-[130px] -z-10 withGradient"></div>
                <button className="block w-full" onClick={() => setShow(true)}>
                {!show && (
                    <>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="rounded-full px-2 h-12 px-4 w-full shadow-lg bg-white" type="text" />
                        </form>
                        <div className="flex absolute top-0 left-0 px-10 items-center justify-between w-full">
                            <p className="text-orange text-xs py-[18px]">Se hai bisogno di me, sono sempre qui</p>
                            <img src={key} className="fill-orange w-6 -mt-1" />
                        </div>
                    </>
                )}
                </button>
            </div>
        </div>
    )
}

export default Chat;