import { GptHandler } from './fetchClient';

export class GptAPI {
  /**
   * @desc Api methods to speak with the BE
   */
  static async sendWizardInfo (payload) {
    const res = await GptHandler('ask_questions/', payload);
    const response = await res.json()
    return response
  }

  static async createIdeasGPT (payload) {
    const res = await GptHandler('create_ideas/', payload);
    const response = await res.json()
    return response
  }

  static async updateItineraryGPT (payload) {
    const res = await GptHandler('update_itinerary/', payload);
    const response = await res.json()
    return response
  }
  
  static async getTheWeather (payload) {
    const res = await GptHandler('weather/', payload);
    const response = await res.json()
    return response
  }

  static async getPlaceDetails (place_id, session_id) {
    const res = await GptHandler(`place/${place_id}?session_id=${session_id}`);
    const response = await res.json()
    return response
  }

  static async getPlaceDescription (place_id, session_id) {
    const res = await GptHandler(`place_dup/${place_id}?session_id=${session_id}`);
    const response = await res.json()
    return response
  }

  static async getVoiceFromText (text) {
    const res = await GptHandler(`text-to-speech`, {
      text: text,
      model_id: "eleven_multilingual_v2",
      stability: 0.3,
      similarity_boost: 0.75,
      style: 0.4,
      use_speaker_boost: true,
      output_format: "mp3_44100_128"
    });
    const response = await res.json()
    return response
  }

  static async sendChatData (payload) {
    const res = await GptHandler('chat/', payload);
    const response = await res.json()
    return response
  }
}
////////////////
// FE DATA (payload)
////////////////
// {
//   question: txt
//   chatHistory: [ 10 stuff ]
//   itinerary: {} optional, 
//   session_id: ''
// }

// Can u please find 3 veggie resturant?
// Can you swap those things? -> {
  // loading: true
// }

////////////
// chat -> FE
////////////
// if i am asking for some options -> /create ideas
// if i am asking for some actions (swapping in the itinerary) -> /update itinerary

// OPTION ->
// loading: true,
// action: create-ideas -> 

// ACTION ->
// loading: true,
// action: update itinerary -> 


////////////
// BE DATA (response)
////////////
// {
//   agent: 'bot' // FE WILL ADD THIS, 
//   text: 'Certo! Ti propongo delle alternative sempre di cucina tradizionale. Fammi sapere quello che ti interessa di più',
//   loading: BOOL,
//   itinerary: {}
//   activities: [
//       {
//           type: 'food',
//           name: 'Ristorante Il Potestà',
//           location: 'Vico Francesco Persichillo, 1, Campobasso',
//           placeId: 'ChIJl3_40CwLOhMRtJkEGrtTY8g',
//           duration: '2h',
//           id: 53456431,
//       },
//       {
//           type: 'food',
//           name: 'Osteria28',
//           location: 'Via Francesco De Attellis, 9, Campobasso',
//           placeId: 'ChIJ3afur9J0OhMRjXxj04o50SM',
//           duration: '2h',
//           id: 53456431,
//       },
//       {
//           type: 'food',
//           name: 'Monticelli, sapere e sapori',
//           location: 'Via Sant\'Antonio Abate, 33, 86100 Campobasso',
//           placeId: 'ChIJ05_R5dJ0OhMR4SgS0M_IuQk',
//           duration: '2h',
//           id: 53456431,
//       },
//   ],
// }