import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
import {chatMock, chatMockSingle} from '../utils/mocks';
import { useLS } from '../contexts/LsContext.js';
import { GptAPI } from "../modules/gptAPI.js";
import { useItinerary } from "./itineraryContext.js";
import { LOCAL_STORAGE_KEYS } from "../utils/CONSTANTS.js";
import { isEmptyObject, sendToDataLayer } from "../utils/utils.js";
import { Labs11 } from "../modules/labs11.js";
import axios from "axios";

  
  export const chatContext = createContext();
  
  const useProvideChat = () => {
    const [chatList, setChatList] = useState(chatMockSingle);
    const [simpleLoading, setSimpleLoading] = useState(false);
    const [isAudio, setIsAudio] = useState(false);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState(false);
    const [showBalloon, setShowBalloon] = useState(true);
    const [initiateBalloon, setInitiateBalloon] = useState(false);

    const LSData = useLS() || {};
    const {setToLS, isInLS, getFromLS} = LSData;

    const itineraryData = useItinerary() || {};
    const {setScheduledDays} = itineraryData;

    const copyChatAndSaveToLS = async (payload) => {
      setChatList(prevChatList => {
        const copyOfChatList = [...prevChatList, payload];
        setToLS('chatLS', copyOfChatList);
        return copyOfChatList;
      });
    }

    useEffect(() => {
      const setBalloonToLS = async () => {
        const utilityLS = await getFromLS('utilityLS');
        if (!utilityLS?.ballonShown || !initiateBalloon) {
          setInitiateBalloon(true)
          const newLS = { ...utilityLS, ballonShown: true };
          setToLS('utilityLS', newLS);
          setTimeout(() => {
            setShowBalloon(false);
          }, 5000);
        }
      };
    
      setBalloonToLS(); // Call the async function inside useEffect
    }, []); 

    const chatResponseModel = (agent, payload) => {
      const response = {
        agent: agent
      }
      if (payload?.response) {
        response.text = payload?.response
      }
      if (payload?.source) {
        response.source = payload?.source
      }
      if (payload?.conversationIdeas?.activities && payload?.conversationIdeas?.activities.length>0) {
        response.options = [...payload?.conversationIdeas?.activities]
      }
      if (payload?.loading) {
        response.loading = payload?.loading
      }
      if (payload?.itinerary) {
        response.itinerary = {...payload?.itinerary}
      }
      if (payload?.confirmation_message) {
        response.confirmation_message = payload?.confirmation_message
      }
      return response;
    }

    const resetChat = () => {
      setChatList(chatMockSingle)
    }

    const answerWithVoice = async (payload) => {
      try {
        setIsLoadingAudio(true);
        const response = await axios.post(
          `${process.env.GATSBY_BE_URL}/text-to-speech`,
          {
            text: payload,
            model_id: "eleven_multilingual_v2",
            voice_settings: {
              stability: 0.3,
              similarity_boost: 0.75,
              style: 0.4,
              use_speaker_boost: true
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
            maxBodyLength: 10 * 1024 * 1024,
            maxContentLength: 10 * 1024 * 1024,
          }
        );
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(audioBlob);
        setIsLoadingAudio(false);
        return url;
        

      } catch (error) {
        setIsLoadingAudio(false);
        console.error('Errore nella conversione del testo in voce:', error.response ? error.response.data : error.message);
        alert('Si è verificato un errore. Per favore, riprova.');
      }
    }


    const updateChat = async (payload) => {
      setSimpleLoading(true);
      await copyChatAndSaveToLS(payload)
      const userLS = await getFromLS(LOCAL_STORAGE_KEYS.user);
      const itineraryLS = await getFromLS(LOCAL_STORAGE_KEYS.itinerary);

      const botResponse = await GptAPI.sendChatData({
        question:payload?.text, 
        session_id:userLS?.session_id, 
        itinerary: {
          touristInfo:userLS?.transformed_query, 
          tripIdeas: {
            dailyPlan : {...itineraryLS}
          },
        }, 
        chatHistory: chatList.slice(Math.max(chatList.length - 10, 0)),
      });

      const session_id = userLS?.session_id;
      await copyChatAndSaveToLS(chatResponseModel('bot', botResponse));
      let changedOptions = false;
      if (botResponse?.tripIdeas?.dailyPlan && !isEmptyObject(botResponse.tripIdeas.dailyPlan) ) {
        await setToLS(LOCAL_STORAGE_KEYS.itinerary, botResponse.tripIdeas.dailyPlan);
        changedOptions = true;
        setScheduledDays(botResponse.tripIdeas.dailyPlan)
      }

      sendToDataLayer({
        event: 'chatResponse',
        data: {
            session_id: session_id,
            changedOptions: changedOptions
        }
    })
      setSimpleLoading(false);
      if (isAudio && (botResponse?.short_response || botResponse?.response)) {
        const url = await answerWithVoice(botResponse?.short_response || botResponse?.response);
        console.log('setAudioUrlsetAudioUrl', url)
        setAudioUrl(url)
        setIsPlaying(true)
      }
    }

    useEffect(() => {
      const setChatOnLoad = async () => {
        const chatInLs = await isInLS('chatLS');
        if (chatInLs) {
          const formattedChat = await JSON.parse(chatInLs);
          setChatList(formattedChat)
          return
        }
      }
      setChatOnLoad();
    }, [])

    return {
        chatList,
        updateChat, 
        simpleLoading,
        resetChat, 
        isAudio,
        setIsAudio, 
        audioUrl, 
        setAudioUrl,
        isPlaying, 
        setIsPlaying,
        isLoadingAudio, 
        setIsLoadingAudio,
        showBalloon, 
        setShowBalloon
    };
  };
  
  export const ChatProvider = ({ children }) => {
    const chat = useProvideChat();
    return (
      <chatContext.Provider value={chat}>{children}</chatContext.Provider>
    );
  };
  
  export const useChat = () => useContext(chatContext);