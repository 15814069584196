import React, { useState, useEffect, useRef } from "react";
import { useChat } from "../contexts/chatContext";
import clsx from "clsx";
import Close from "./icons/iconsClose";
import SingleChat from "./singleChat";
import LoadingChat from "./loadingChat";
import play from '../assets/images/play.svg'
import VoiceBallonDisclaimer from "./VoiceBallonDisclaimer";

const ChatView = ({ show, setShow, setInputValue, inputValue, handleSubmit, inputRef, endOfContainerRef }) => {
    const chatData = useChat() || {};
    const { chatList, 
        simpleLoading, 
        isAudio, 
        setIsAudio, 
        audioUrl, 
        setAudioUrl, 
        isPlaying, 
        setIsPlaying, 
        isLoadingAudio,
        showBalloon,
    } = chatData;
    const [modalHeight, setModalHeight] = useState(null);

    const audioRef = useRef();

    useEffect(() => {
        if (audioRef.current && isPlaying) {
          audioRef.current.play();
        }
      }, [audioUrl, isPlaying]);

      const toggleAudio = () => {
        setIsAudio(!isAudio);
        if (!isAudio) {
            setAudioUrl(false)
        }
      }


    const handleAudioEnded = () => {
        setIsPlaying(false);
      };
    
      // Function to handle replay button click
      const handleReplay = () => {
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
          audioRef.current.play();
          setIsPlaying(true);
        }
      };

    const scrollToEndOfChat = () => {
        const endOfChat = document.getElementById('endOfChat');
        endOfChat.scrollIntoView({
            behavior: "smooth",
        });
    }

    useEffect(() => {
        function isKeyboardOpen() {
            const { innerHeight, innerWidth } = window;
            const vh = Math.min(innerHeight, innerWidth);
            // Assuming keyboard height threshold as 100px (adjust as needed)
            return vh < innerHeight;
        }

        function calculateModalHeight() {
            if (show && isKeyboardOpen()) {
                const { innerHeight } = window;
                // Assuming you have a fixed header of height 90px
                const fixedHeaderHeight = 64;
                const availableHeight = innerHeight - fixedHeaderHeight;
                setModalHeight(availableHeight);
                document.body.classList.add('overflow-hidden');
            } else {
                setModalHeight(null);
                document.body.classList.remove('overflow-hidden');
            }
        }

        // Call the function when show or window size changes
        setInputValue('')
        calculateModalHeight();
        window.addEventListener('resize', calculateModalHeight);
        return () => window.removeEventListener('resize', calculateModalHeight);
    }, [show]);

    useEffect(() => {
        scrollToEndOfChat();
    }, [chatList?.length])

    return (
        <div
            className={clsx("overflow-scroll chatView w-full shadow-lg bg-light-grey fixed right-0 transition-all z-30", !show ? 'top-[100vh]' : 'top-[63px]')}
            style={{height: modalHeight ? modalHeight : 'auto'}}
        >
            {show && (
                <button onClick={() => setShow(false)} className="block p-3 fixed top-[85px] right-[15px] shadow-md h-10 w-10 rounded-full flex items-center bg-white">
                    <Close className="cursor-pointer" fill="fill-orange" />
                </button>
            )}
            {/* <button onClick={handleReplay}>Riascolta</button> */}
            <div className="pt-16 pb-32">
                {chatList && chatList.map((el, index) => {
                    return (
                        <SingleChat
                            isAudio={isAudio} 
                            isPlaying={isPlaying} 
                            isLoadingAudio={isLoadingAudio} 
                            data={el} 
                            key={index} 
                            agent={el?.agent} 
                            source={el?.source} 
                            text={el?.text} 
                            options={el?.options} 
                            confirmation_message={el?.confirmation_message} 
                        />
                    )
                })}
                {simpleLoading && <LoadingChat key={new Date().toISOString()} />}
            </div>
            <audio
                ref={audioRef}
                src={audioUrl}
                onEnded={handleAudioEnded}
            >
                Il tuo browser non supporta l'elemento audio.
            </audio>


            {show && (    
                <div className="w-full px-4 fixed bottom-10 rounded-full z-30 left-0">
                    <div className="relative">
                        {showBalloon && <VoiceBallonDisclaimer />}
                        <form className="relative" onSubmit={(e) => handleSubmit(e)}>
                            <input 
                                disabled={simpleLoading} 
                                onFocus={scrollToEndOfChat} 
                                ref={inputRef} 
                                value={inputValue} 
                                onChange={(e) => setInputValue(e.target.value)} 
                                className="rounded-full px-2 h-12 px-4 w-full shadow-lg mainChatInput pr-[48px]" 
                                type="text" />
                        </form>
                        
                        {/* TOGGLE AUDIO */}
                        <button disabled={simpleLoading} onClick={toggleAudio} className={clsx(isAudio ? "active" : "" , "flex items-center absolute top-[9px] right-[9px] w-[30px] h-[30px] bg-orange rounded-full p-2 buttonAudio cursor-pointer")}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path fill={isAudio ? '#FF7804' : '#fff'} d="M333.1 34.8C344.6 40 352 51.4 352 64l0 384c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L163.8 352 96 352c-35.3 0-64-28.7-64-64l0-64c0-35.3 28.7-64 64-64l67.8 0L298.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zm172 72.2c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C507.3 341.3 528 301.1 528 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C466.1 199.1 480 225.9 480 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C425.1 284.4 432 271 432 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5z"/>
                            </svg>
                        </button>
                        
                        {/* REPLAY AUDIO */}
                        {isAudio && audioUrl && (
                            <button disabled={simpleLoading} onClick={handleReplay} className={clsx("flex items-center absolute top-[9px] right-[44px] w-[30px] h-[30px] bg-orange rounded-full p-2 buttonAudio cursor-pointer")}>
                                <img className="ml-[2px]" src={play} />
                            </button>
                        )}
                    </div>
                </div>
            )}
            <div ref={endOfContainerRef} id="endOfChat"></div>
        </div>
    )
}

export default ChatView